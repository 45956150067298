import styled from "styled-components"
import { Styles as ButtonStyles } from "../Button"
import { Styles as CTAButtonStyles } from "../CTAButton"

const CTABlock = styled.div`
  background-color: ${({ theme }) => theme.color["gray-100"]};

  .inner {
    display: flex;
    justify-content: center;
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
  }

  .content {
    max-width: ${({ theme }) => theme.space[15]};
    text-align: center;
    padding: ${({ theme }) => theme.space[3]};
  }

  ${CTAButtonStyles.CTAButton} {
    width: 100%;

    @media ${({ theme }) => theme.device.tablet} {
      margin: 3rem 0;
    }

    ${ButtonStyles.Button} {
      display: block;
      width: 100%;

      @media ${({ theme }) => theme.device.tablet} {
        max-width: 500px;
      }
    }
  }
`

export default {
  CTABlock,
}
