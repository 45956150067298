import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Container from "../../layout/Container"
import Styles from "./Styles"

const PageHeader = ({
  heading,
  introduction,
  image,
  imageAlt,
  published,
  updated,
}) => {
  return (
    <Styles.PageHeader>
      <Container>
        <h1>{heading}</h1>
        <div dangerouslySetInnerHTML={{ __html: introduction }} />
        {published ||
          (updated && (
            <p className="meta">{updated && `Last updated: ${updated}`}</p>
          ))}
        {image && (
          <img className="header-image" src={image} alt={imageAlt || ""} />
        )}
      </Container>
    </Styles.PageHeader>
  )
}

PageHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  published: PropTypes.string,
  updated: PropTypes.string,
}

PageHeader.defaultProps = {
  image: undefined,
  imageAlt: undefined,
  published: undefined,
  updated: undefined,
}

export default PageHeader
