import React from "react"
import PropTypes from "prop-types"
import Styles from "./Styles"

const PageContent = ({ children, content }) => {
  return (
    <Styles.PageContent>
      {content ? (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        children
      )}
    </Styles.PageContent>
  )
}

PageContent.propTypes = {
  content: PropTypes.string,
}

PageContent.defaultProps = {
  content: undefined,
}

export default PageContent
