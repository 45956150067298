import styled from "styled-components"
import { Styles as ButtonStyles } from "../Button"

const CTAButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  ${ButtonStyles.Button} {
    max-width: ${({ theme }) => theme.space[12]};
  }

  span {
    display: block;
    margin: ${({ theme }) => `${theme.space[1]} auto`};
    font-size: ${({ theme }) => theme.fontSize[1]};
    text-align: center;
    color: ${({ theme }) => theme.color["gray-500"]};

    @media ${({ theme }) => theme.device.mobileL} {
      font-size: ${({ theme }) => theme.fontSize[2]};
    }
  }
`

export default {
  CTAButton,
}
