import React from "react"
import PropTypes from "prop-types"

import CTAButton from "../CTAButton"
import Styles from "./Styles"

const CTABlock = ({ heading, text, buttonLabel, subText, link }) => {
  return (
    <Styles.CTABlock>
      <div className="inner">
        <div className="content">
          <h2>{heading}</h2>
          {text && <p className="lead">{text}</p>}
          <CTAButton label={buttonLabel} text={subText} link={link} />
        </div>
      </div>
    </Styles.CTABlock>
  )
}

CTABlock.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  buttonLabel: PropTypes.string,
  subText: PropTypes.string,
  link: PropTypes.string,
}

CTABlock.defaultProps = {
  heading: "Retain and reward your mortgage customers",
  text: undefined,
  buttonLabel: "Request a demo",
  subText: undefined,
  link: undefined,
}

export default CTABlock
