import styled from "styled-components"

const PageHeader = styled.div`
  padding: ${({ theme }) => `${theme.space[6]} ${theme.space[4]}`};
  background-color: ${({ theme }) => theme.color["gray-200"]};

  @media ${({ theme }) => theme.device.mobileL} {
    background-color: ${({ theme }) => theme.color["gray-100"]};
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => `${theme.space[8]} ${theme.space[4]}`};
  }

  p {
    font-size: ${({ theme }) => theme.fontSize[3]};
    color: ${({ theme }) => theme.color["gray-700"]};

    @media ${({ theme }) => theme.device.mobileM} {
      font-size: ${({ theme }) => theme.fontSize[4]};
    }

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ theme }) => theme.fontSize[5]};
    }
  }

  /* .header-image {
    max-width: 100%;
    display: block;

    @media ${({ theme }) => theme.device.mobileL} {
      margin: 2rem auto 0;
      max-height: 400px;
    }
  } */

  .meta {
    font-size: ${({ theme }) => theme.fontSize[2]};
    text-transform: uppercase;
  }
`

export default {
  PageHeader,
}
