/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"

import Container from "../components/layout/Container"
import Layout from "../components/layout/Layout"
import PageContent from "../components/common/PageContent"
import PageHeader from "../components/common/PageHeader"
import SEO from "../components/utility/SEO"

import CTABlock from "../components/common/CTABlock"

const DefaultPage = ({ data: { prismicDefaultPage } }) => {
  const { data } = prismicDefaultPage
  return (
    <Layout audienceType={data.audience_type}>
      <SEO
        title={data.title.text}
        description={data.meta_description.text}
        canonicalUrl={data.canonical_url.text}
      />
      <PageHeader
        heading={data.heading.text}
        introduction={data.introduction.html}
        image={data.header_image.url}
        imageAlt={data.header_image.alt}
      />
      <Container>
        <PageContent content={data.content.html} />
      </Container>
      {data.cta_heading.text && (
        <CTABlock
          heading={data.cta_heading.text}
          text={data.cta_text.text}
          buttonLabel={data.cta_button_label.text}
          subText={data.cta_sub_text.text}
        />
      )}
    </Layout>
  )
}

export default DefaultPage

export const pageQuery = graphql`
  query DefaultPageBySlug($uid: String!) {
    prismicDefaultPage(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        audience_type
        meta_description {
          text
        }
        canonical_url {
          text
        }
        heading {
          text
        }
        introduction {
          html
        }
        header_image {
          url
          alt
        }
        content {
          html
        }
        cta_heading {
          text
        }
        cta_text {
          text
        }
        cta_button_label {
          text
        }
        cta_sub_text {
          text
        }
      }
    }
  }
`
