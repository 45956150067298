import React from "react"
import PropTypes from "prop-types"
import Button from "../Button"
import Styles from "./Styles"

const CTAButton = ({ label, text, link }) => {
  return (
    <Styles.CTAButton>
      <Button label={label} mode="primary" size="l" linkTo={link} />
      <span>{text}</span>
    </Styles.CTAButton>
  )
}

CTAButton.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
}

CTAButton.defaultProps = {
  label: "Request a demo",
  text: "Early access available",
  link: "/register-interest",
}

export default CTAButton
